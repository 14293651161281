.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	padding-top: 20px;
}

.drawer {
	position: fixed;
	top: 0;
	left: 0;
	width: 320px;
	height: 100vh;
	background-color: #fff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	z-index: 99999;
	border-top-right-radius:20px ;
	border-bottom-right-radius:20px ;
}
